import type {
  I$W,
  IWixAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { postContentElementIds } from './elementIds';

const PAYWALL_BUTTON_ID = 'c3gvh';

export class PostContentController {
  private readonly ui = {
    richContentViewer: this.$w(
      `#${postContentElementIds.richContentViewerId}`,
    ) as $w.RichContentViewer,

    paywallRichContentViewer: this.$w(
      `#${postContentElementIds.paywallRichContentViewerId}`,
    ) as $w.RichContentViewer,

    multiStateBox: this.$w(
      `#${postContentElementIds.multiStateBoxId}`,
    ) as $w.MultiStateBox,
  };

  private readonly facade = new BlocksWidgetFacade(
    this.flowAPI,
    this.wixCodeApi,
  );

  constructor(
    private readonly $w: I$W,
    private readonly flowAPI: PlatformControllerFlowAPI,
    private readonly wixCodeApi: IWixAPI,
  ) {}

  applyStateToUi(model: PostPageRenderModel) {
    const { post } = model;
    const metaSiteId =
      this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
    const isMobileView = this.flowAPI.environment.isMobile;
    const isEditor = this.flowAPI.environment.isEditor;

    if (isEditor || !post.preview) {
      this.ui.paywallRichContentViewer.delete();
    }

    this.ui.richContentViewer.metaSiteId = metaSiteId;
    this.ui.richContentViewer.isMobileView = isMobileView;
    this.ui.richContentViewer.content = post.richContent;

    if (post.paywallRichContent && !isEditor && post.preview) {
      this.ui.paywallRichContentViewer.metaSiteId = metaSiteId;
      this.ui.paywallRichContentViewer.isMobileView = isMobileView;
      this.ui.paywallRichContentViewer.content = post.paywallRichContent;
      // @ts-expect-error
      this.ui.paywallRichContentViewer.onNodeClick(async ({ node }) => {
        if (
          node.type === 'BUTTON' &&
          node.buttonData?.type === 'ACTION' &&
          node.id === PAYWALL_BUTTON_ID
        ) {
          await this.facade.navigateToPricingPage(post);
        }
      });
    }
  }
}
